.Languages a {
  margin: 0 auto;
  padding-left: 100px;
  padding-right: 50px;
}
.Languages a:hover {
  padding-left: 10px;
  padding-right: 10px;
  font-size: 100px;
  transition: all 300ms ease-in-out;
}
div.Languages {
  background-color: white;
  padding-top: 50px;
  padding-bottom: 50px;
  transition: all;
  transition-duration: 250ms;
}
div.Languages:hover {
  padding-top: 20px;
  padding-bottom: 20px;
}

@media (max-width: 900px) {
  .Languages a {
    margin: 0 auto;
    padding-left: 10px;
    padding-right: 0px;
  }
  .Languages a:hover {
    font-size: 20px;
  }
}
