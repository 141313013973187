.Hero {
  margin: 70px auto;
}
.Hero h1 {
  font-family: "Playfair Display", serif;
  font-weight: 700;
  font-size: 64px;
  margin-bottom: 50px;
}
.Hero h1,
p {
  color: black;
}
.Hero h2,
p {
  font-family: "Poppins", sans-serif;
  margin-bottom: 40px;
}
.Hero h2 {
  color: rgba(39, 33, 66, 1);
  font-size: 30px;
}
.Hero p {
  font-size: 12px;
  letter-spacing: 2px;
  margin-bottom: 53px;
}
.Hero button {
  font-size: 16px;
  color: white;
  background-color: rgba(7, 67, 10, 0.619);
  text-decoration: none;
  padding: 18px 46px;
  letter-spacing: 5px;
  margin-top: 20px;
  border: none;
}
.Hero button:hover {
  color: rgb(7, 67, 10);
  border: solid;
  border-width: thin;
  border-color: rgb(7, 67, 10);
  background-color: rgba(7, 67, 10, 0);
}
@media (max-width: 900px) {
  .Hero {
    margin: 0 auto;
    margin-top: 10px;
    margin-bottom: 25px;
  }
  .Hero h1 {
    font-size: 32px;
    margin-bottom: 25px;
  }
  .Hero h2,
  p {
    margin-bottom: 20px;
  }
  .Hero h2 {
    font-size: 15px;
  }
  .Hero p {
    font-size: 10px;
    margin-top: 0;
    margin-bottom: 20px;
  }
  .Hero button {
    font-size: 12px;
    padding: 15px 30px;
    margin: 0 auto;
  }
}
@media (max-width: 576px) {
  .Hero {
    margin-bottom: 50px;
  }
  .Hero h1 {
    margin-top: 25px;
  }

  .Hero p {
    margin-bottom: 35px;
  }
}
