.ContactMe {
  display: block;
  margin: 0 auto;
  margin-top: 70px;
  margin-bottom: 70px;
  background-color: rgba(7, 67, 10, 0.225);
  max-width: 900px;
}
.ContactMe h2 {
  font-family: "Playfair Display", serif;
  font-weight: 700;
  color: black;
  margin-top: 37px;
  margin-bottom: 10px;
}
.ContactMe p {
  font-family: "Poppins", sans-serif;
  font-size: 12px;
  letter-spacing: 2px;
}
.ContactMe a {
  color: white;
  position: relative;
  top: 75px;
  font-size: 12px;
  background-color: rgba(7, 67, 10, 0.619);
  text-decoration: none;
  padding: 15px 30px;
  letter-spacing: 5px;
  color: rgb(255, 255, 255);
}
.ContactMe a:hover {
  color: rgb(7, 67, 10);
  border: solid;
  border-width: thin;
  border-color: rgb(7, 67, 10);
  background-color: rgba(7, 67, 10, 0);
}
@media (max-width: 900px) {
  .ContactMe {
    max-width: 600px;
  }
  .ContactMe h2 {
    font-size: 25px;
  }
  .ContactMe p {
    font-size: 10px;
    margin-bottom: 30px;
    margin-top: 10px;
  }
  .ContactMe a {
    position: relative;
    top: 45px;
    font-size: 12px;
  }
}
@media (max-width: 576px) {
  .ContactMe {
    max-width: 400px;
  }
  .ContactMe h2 {
    font-size: 15px;
  }
  .ContactMe p {
    font-size: 6px;
    margin-bottom: 30px;
  }
  .ContactMe a {
    position: relative;
    top: 35px;
    font-size: 9px;
    padding: 10px 15px;
  }
}
