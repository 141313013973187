.Project {
  background-color: rgba(245, 248, 245, 0.499);
  border-radius: 5px;
  box-shadow: 0 30px 50px rgba(7, 67, 10, 0.17);
  transition: all 100ms ease-in-out;
}
.Project:hover {
  width: 755px;
  height: 458px;
  box-shadow: 0 30px 50px rgba(7, 67, 10, 0.37);
}
.Project h2 {
  color: rgba(7, 67, 10, 0.692);
  font-family: "Playfair Display", serif;
  padding-top: 20px;
  margin-top: 50px;
  margin-bottom: 50px;
}
.Project p {
  padding-bottom: 30px;
  padding-top: 30px;
  margin: 0 auto;
}
@media (max-width: 900px) {
  .Project:hover {
    width: 280px;
    height: 320px;
  }
}
@media (max-width: 576px) {
  .Project:hover {
    width: 390px;
    height: 390px;
  }
}
