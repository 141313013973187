form {
  margin: 0 auto;
  background-color: rgba(7, 67, 10, 0.225);
  max-width: 900px;
  display: flex;
  justify-content: center;
}
.form_container_lg form {
  margin-top: 250px;
  margin-bottom: 70px;
  padding: 70px;
}
h1 {
  font-family: "Playfair Display", serif;
  color: black;
}
.form_container_lg h1 {
  font-size: 100px !important;
  padding-right: 100px;
  margin-top: 100px;
  margin-bottom: 0;
}
.form_container_lg form {
  margin: 0 auto;
  display: block;
}

button {
  border: none;
  color: white;
  background-color: rgba(7, 67, 10, 0.619);
  text-decoration: none;
  letter-spacing: 5px;
  color: rgb(255, 255, 255);
  padding: 15px 30px;
}
.form_container_lg button {
  font-size: 12px;
  margin-top: 30px;
}
button:hover {
  color: rgb(7, 67, 10);
  border: solid;
  border-width: thin;
  border-color: rgb(7, 67, 10);
  background-color: rgba(7, 67, 10, 0);
}
.center {
  margin-top: 30px;
}
.messege {
  margin-top: 20px;
  padding: 25px;
  padding-right: 100px;
  padding-legt: 100px;
}

.form_container_sm form {
  padding: 70px;
}

.form_container_sm h1 {
  font-size: 50px !important;
  padding-right: 100px;
  margin-top: 10px;
  margin-bottom: 0;
  font-family: Playfair Display, serif;
  font-weight: 700;
}

.form_container_sm input {
  margin: 0 auto;
  display: block;
  max-width: 200px;
  width: 190px;
  padding-top: 20px;
  padding-bottom: 20px;
  margin-bottom: 30px;
  font-family: Poppins, sans-serif;
  letter-spacing: 2px;
}
.form_container_sm button {
  margin: 0 auto;
  display: block;
}
